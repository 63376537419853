import React, {
  ComponentProps,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { Parallax } from 'react-parallax';
import {
  Typography,
  Timeline,
  Button,
  Drawer,
  message,
  Tooltip,
  Rate,
} from 'antd';
import Icon, {
  PlayCircleOutlined,
  FacebookFilled,
  YoutubeFilled,
  InstagramOutlined,
  CaretUpOutlined,
  ShareAltOutlined,
  DownOutlined,
  UpOutlined,
  TwitterOutlined,
  CopyOutlined,
  StarFilled,
  LockOutlined,
  RightCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
  useParams,
  useLocation,
  Redirect,
  Link as RouteLink,
} from 'react-router-dom';
import moment from 'moment';
import { format } from 'date-fns';
import { useAsyncData } from '../../hook/useAsyncData';
import { Div, Page, Icon as HcmIcon, BottomNavigation } from '../../framework';
import { openModal, setMenuMode, setFlyoutOpen } from '../../store/app.slice';
import { useTalentService } from '../../service/talent.service';
import { featureToggle, serviceDomain } from '../../../config/app.config';
import { useResetScroll } from '../../helper/hook';

import Menu from '../../component/Menu/Menu';
import Footer from '../../component/Footer/Footer';
import { isClient } from '../../helper/detect';
import { RatingStats, Talent } from '../../helper/type';
import { RootState } from '../../store/store';
import { socialMediaUrl } from '../../helper/constants';
import { formatNumber, formatText } from '../../helper/object';
import { useRatingService } from '../../service/rating.service';
import { setRatingStats, setRatings } from '../../store/talent.slice';

import './Profile.scss';

const { DouyinSvg, TikTokSvg, WhatsAppSvg, XiaoHongShuSvg, TwitterSvg } =
  HcmIcon;
const { Title, Text, Paragraph, Link } = Typography;

const maxExperiencesDisplay = 4;
const maxGalleryItemDisplay = 5;

type LocationState = {
  isAlreadyOnboarded: boolean;
  fromApplicantList: boolean;
  openModalPayload: any;
};

const TalentProfilePage = (props: ComponentProps<any>) => {
  useResetScroll();
  const { id } = useParams<{ id: string }>();
  const { search, state } = useLocation<LocationState>();
  const { data = {}, error } = useAsyncData(TalentProfilePage, props, {
    id,
  });

  const talent: Talent = data;

  const talentInfoCardRef = useRef<HTMLDivElement>(null);

  const { authUser, authHirer, impersonator } = useSelector(
    (state: RootState) => state.user
  );
  const { mappedRatings, mappedRatingStats } = useSelector(
    (state: RootState) => state.talent
  );
  const isProfileOwner = authUser.username === id;
  const isImpersonateTalent =
    impersonator.impersonateId === id && impersonator.impersonateKey;
  const ratingStats = mappedRatingStats[id];

  const dispatch = useDispatch();
  const ratingService = useRatingService();
  const [shouldShowDrawer, setShouldShowDrawer] = useState(false);
  const [shouldShowMoreExperience, setShouldShowMoreExperience] =
    useState(false);
  const [hideMenu, setHideMenu] = useState(false);
  const [hideShare, setHideShare] = useState(false);
  const [listRatingKey, setListRatingKey] = useState<string>();

  const hasSocialLinks =
    (talent.instagramId && talent.instaFollowers) ||
    (talent.tiktokId && talent.tiktokFollowers) ||
    (talent.douyinId && talent.douyinFollowers) ||
    (talent.youtubeId && talent.ytSubscribers) ||
    (talent.xiaohongshuId && talent.xhsFollowers) ||
    (talent.twitterId && talent.twitterFolowers) ||
    (talent.facebookId && talent.facebookFollowers);

  const onScroll = () => {
    const infoCardY = talentInfoCardRef.current?.getClientRects()[0].y;
    dispatch(
      setMenuMode({ isShrank: typeof infoCardY === 'number' && infoCardY < 0 })
    );
  };

  const sortedRatings = useMemo(() => {
    if (!mappedRatings[id]) return [];

    return Object.values(mappedRatings[id])
      .filter((rating) => rating.writtenReview)
      .sort((a, b) => b.createdAt.localeCompare(a.createdAt));
  }, [mappedRatings[id]]);

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
    dispatch(setMenuMode({ isShrank: false }));
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    if (state?.isAlreadyOnboarded === false) {
      dispatch(
        openModal({
          modal: 'onboard-prompt-email-verification',
        })
      );
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(search);
    if (params.get('hidemenu') === '1') setHideMenu(true);
    if (params.get('hideshare') === '1') setHideShare(true);
  }, []);

  useEffect(() => {
    (async () => {
      if (!featureToggle.rating) return;

      const { data, key } = await ratingService.listRatingsByTalent(id);
      setListRatingKey(key);
      dispatch(setRatings({ talentId: id, ratings: data }));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (mappedRatingStats[id] || !featureToggle.rating) return;

      if (
        isImpersonateTalent ||
        authHirer.hirerId ||
        authUser.username === id
      ) {
        const stats = await ratingService.getRatingStats(id);
        dispatch(setRatingStats(stats));
      }
    })();
  }, [authUser, authHirer]);

  const openGallery = (index = 0) => {
    dispatch(
      openModal({
        modal: 'media-gallery',
        config: {
          context: {
            gallery: talent.gallery,
            index,
          },
        },
      })
    );
  };

  const handleCopyLink = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    await navigator.clipboard.writeText(
      `${window.location.origin}/t/${id}?src=link`
    );
    message.success({
      content: 'Link Copied!',
    });
    setShouldShowDrawer(false);
  };

  const handleLoadMoreRatings = async () => {
    if (!listRatingKey || !featureToggle.rating) return;

    const { data, key } = await ratingService.listRatingsByTalent(
      id,
      listRatingKey
    );
    setListRatingKey(key);
    dispatch(setRatings({ talentId: id, ratings: data }));
  };

  if (error) {
    return <Redirect to='/' />;
  }

  return (
    <Page className='page-talent-profile'>
      <Helmet>
        <title>{`${talent.name} | HeyCast.Me`}</title>
        <meta
          name='description'
          content='The right role. The right talent. The right platform.'
        />
        <meta property='og:title' content={`${talent.name} | HeyCast.Me`} />
        <meta property='og:url' content={`${serviceDomain.ui}/t/${id}`} />
        <meta
          property='og:description'
          content='The right role. The right talent. The right platform.'
        />
        <meta property='og:image' content={`${talent.profilePhotoThumbnail}`} />
      </Helmet>
      <Div className='mobile-view'>
        {!hideMenu && <Menu />}
        <Parallax
          bgImage={talent.profilePhoto}
          strength={500}
          blur={{ min: -10, max: 10 }}
          bgImageStyle={{ height: '80vh', width: '100%', objectFit: 'cover' }}
        >
          <div className='profile-photo'>
            <div className='photo-info'>
              <Title
                level={2}
                style={{
                  color: '#fff',
                  marginBottom: 0,
                  fontSize: '2.25rem',
                  fontFamily: 'Montserrat Alternates',
                }}
              >
                {talent.name}
              </Title>
              <Paragraph
                style={{
                  color: '#fff',
                  marginTop: '0.25rem',
                  fontFamily: 'Montserrat Alternates',
                }}
              >
                {new Date().getFullYear() - new Date(talent.dob).getFullYear()},{' '}
                {talent.roles?.map(
                  (role: any, index: number) =>
                    `${index !== 0 ? ', ' : ''}${role}`
                )}
              </Paragraph>
              {featureToggle.rating && (
                <Paragraph
                  style={{
                    color: '#fff',
                    marginTop: '0.5rem',
                    fontWeight: 600,
                  }}
                >
                  {!isImpersonateTalent &&
                    !authHirer.hirerId &&
                    !authUser.username && (
                      <>
                        <LockOutlined />{' '}
                        <RouteLink
                          to='/hirer/login'
                          style={{
                            color: 'white',
                            textDecoration: 'underline',
                          }}
                        >
                          Login to view this talent&apos;s ratings
                        </RouteLink>
                      </>
                    )}
                  {featureToggle.rating &&
                    ratingStats &&
                    (isImpersonateTalent ||
                      id === authUser.username ||
                      (authHirer.hirerId &&
                        ratingStats?.totalRatingCount > 0)) && (
                      <Button
                        type='text'
                        onClick={() =>
                          document
                            .getElementById('section-rating')
                            ?.scrollIntoView({ behavior: 'smooth' })
                        }
                        style={{
                          fontWeight: 600,
                          color: 'white',
                          padding: 0,
                        }}
                      >
                        <StarFilled /> {ratingStats.totalRatingCount} ratings
                      </Button>
                    )}
                </Paragraph>
              )}
              <Paragraph
                style={{
                  color: '#fff',
                  marginTop: '0.75rem',
                  marginBottom: '2rem',
                  fontFamily: 'Montserrat Alternates',
                }}
              >
                {talent.bio}
              </Paragraph>
            </div>
          </div>
        </Parallax>
        <div className='info' ref={talentInfoCardRef}>
          <Div className='arrow-wrapper'>
            <CaretUpOutlined />
          </Div>
          {!hideShare && (
            <Div
              flex
              style={{
                position: 'sticky',
                top: isProfileOwner
                  ? 'calc(100vh - 3rem - 86px)'
                  : 'calc(100vh - 3rem)',
                zIndex: 100,
              }}
            >
              <Button
                block
                style={{ marginRight: '0.5rem' }}
                onClick={() => setShouldShowDrawer(true)}
              >
                <ShareAltOutlined /> Share
              </Button>
              {!isProfileOwner && !state?.fromApplicantList ? (
                <Button
                  type='primary'
                  block
                  href={`https://api.whatsapp.com/send?phone=+601154110075&text=Hi,%20I%20would%20like%20to%20engage%20talent%20${id}.`}
                  target='_blank'
                >
                  Engage Talent
                </Button>
              ) : null}
              {!isProfileOwner && state?.fromApplicantList === true ? (
                <Button
                  type='primary'
                  block
                  onClick={() => dispatch(openModal(state.openModalPayload))}
                >
                  Update Status
                </Button>
              ) : null}
            </Div>
          )}
          <Drawer
            placement='bottom'
            closable={false}
            visible={shouldShowDrawer}
            onClose={() => setShouldShowDrawer(false)}
          >
            <Title level={4}>Share to</Title>
            <Div flex>
              <a
                className='share-icon'
                href={`whatsapp://send?text=Hey, check out this talent, ${
                  talent.name
                } at ${isClient ? window.location.origin : ''}/t/${id}?src=wa`}
                data-action='share/whatsapp/share'
                target='_blank'
                rel='noreferrer'
              >
                <Icon component={WhatsAppSvg} />
                <div>WhatsApp</div>
              </a>
              <a
                className='share-icon'
                href={`https://www.facebook.com/sharer/sharer.php?u=${
                  isClient ? window.location.origin : ''
                }/t/${id}?src=fb`}
                target='_blank'
                rel='noreferrer'
              >
                <FacebookFilled
                  style={{ fontSize: '3.2rem', color: '#3b5998' }}
                />
                <div>Facebook</div>
              </a>
              <a
                className='share-icon'
                href={`https://twitter.com/share?url=${
                  isClient ? window.location.origin : ''
                }/t/${id}?src=tw`}
              >
                <TwitterOutlined
                  style={{ fontSize: '3.2rem', color: '#1da1f2' }}
                />
                <div>Twitter</div>
              </a>
              <a className='share-icon' onClick={handleCopyLink}>
                <CopyOutlined style={{ fontSize: '3rem', color: '#565656' }} />
                <div>Copy Link</div>
              </a>
            </Div>
          </Drawer>
          <section>
            <Title level={4} style={{ margin: '3rem 0 0.25rem 0' }}>
              About Me
            </Title>
            <Paragraph>Visual Age</Paragraph>
            {talent.playableAge?.from ? (
              <Text className='attribute-detail'>
                {talent.playableAge.from}-{talent.playableAge.to} years old
              </Text>
            ) : null}
            <Paragraph>State</Paragraph>
            <Text className='attribute-detail'>{talent.state}</Text>
            <Paragraph>Date of Birth</Paragraph>
            <Text className='attribute-detail'>
              {moment(new Date(talent.dob)).format('Do MMM YYYY')}
            </Text>
            <Div flex>
              <Div>
                <Paragraph>Ethnicity</Paragraph>
                <Text className='attribute-detail'>{talent.ethnicity}</Text>
              </Div>
              {talent.race ? (
                <Div>
                  <Paragraph>Race</Paragraph>
                  <Text className='attribute-detail'>{talent.race}</Text>
                </Div>
              ) : null}
              {talent.countryOfOrigin &&
              talent.countryOfOrigin.toLowerCase() !== 'malaysia' ? (
                <Div>
                  <Paragraph>Country</Paragraph>
                  <Text className='attribute-detail'>
                    {talent.countryOfOrigin}
                  </Text>
                </Div>
              ) : null}
            </Div>
            <Div flex>
              <Div>
                <Paragraph>Eye Color</Paragraph>
                <Text className='attribute-detail'>{talent.eyeColor}</Text>
              </Div>
              <Div>
                <Paragraph>Hair Color</Paragraph>
                <Text className='attribute-detail'>{talent.hairColor}</Text>
              </Div>
            </Div>
            <Div flex>
              <Div>
                <Paragraph>Height</Paragraph>
                <Text className='attribute-detail'>{talent.height}cm</Text>
              </Div>
              <Div>
                <Paragraph>Weight</Paragraph>
                <Text className='attribute-detail'>{talent.weight}kg</Text>
              </Div>
            </Div>
            <Div flex>
              <Div>
                <Paragraph>Body Type</Paragraph>
                <Text className='attribute-detail'>{talent.bodyType}</Text>
              </Div>
              <Div>
                <Paragraph>Chest</Paragraph>
                <Text className='attribute-detail'>
                  {talent.chestSize}&quot;
                </Text>
              </Div>
              <Div>
                <Paragraph>Waist</Paragraph>
                <Text className='attribute-detail'>
                  {talent.waistSize}&quot;
                </Text>
              </Div>
              <Div>
                <Paragraph>Hip</Paragraph>
                <Text className='attribute-detail'>{talent.hipSize}&quot;</Text>
              </Div>
            </Div>
            <Div flex>
              <Div>
                <Paragraph>Shirt Size</Paragraph>
                <Text className='attribute-detail'>{talent.shirtSize}</Text>
              </Div>
              <Div>
                <Paragraph>Pant Size</Paragraph>
                <Text className='attribute-detail'>{talent.pantSize}</Text>
              </Div>
              <Div>
                <Paragraph>Shoe Size (UK)</Paragraph>
                <Text className='attribute-detail'>{talent.shoeSize}</Text>
              </Div>
            </Div>

            {talent.twitterFolowers && (
              <Div flex>
                <Div>
                  <Paragraph>Twitter Followers</Paragraph>
                  <Text className='attribute-detail'>
                    {formatNumber(Number(talent.twitterFolowers))}
                  </Text>
                </Div>
              </Div>
            )}
          </section>
          <section>
            <Div flex style={{ alignItems: 'center' }}>
              <Title level={4} style={{ margin: '0.5rem 0' }}>
                Gallery
              </Title>
              <Div style={{ textAlign: 'right' }}>
                {talent.gallery?.length > maxGalleryItemDisplay && (
                  <Link onClick={() => openGallery()}>
                    Show All ({talent.gallery.length})
                  </Link>
                )}
              </Div>
            </Div>
            <Div flex style={{ flexWrap: 'wrap' }}>
              {talent.gallery
                ?.slice(0, maxGalleryItemDisplay)
                ?.map((item: any, index: number) => (
                  <a
                    key={index}
                    onClick={() => openGallery(index)}
                    className='image-container'
                  >
                    <img src={item.thumbnail} />
                  </a>
                ))}
              {talent.gallery?.length > maxGalleryItemDisplay && (
                <a
                  onClick={() => openGallery(maxGalleryItemDisplay)}
                  className='image-container'
                >
                  <img src={talent.gallery[maxGalleryItemDisplay].thumbnail} />
                  <Text className='show-more'>
                    +{talent.gallery.length - maxGalleryItemDisplay}
                  </Text>
                </a>
              )}
            </Div>
          </section>
          {talent.experiences?.length ? (
            <section>
              <Title level={4}>Experiences</Title>
              {talent.experiences?.length ? (
                <Timeline mode='left'>
                  {!shouldShowMoreExperience &&
                    [...talent.experiences]
                      ?.sort((a, b) => {
                        if (b.year === a.year && b.createdAt && a.createdAt)
                          return b.createdAt?.localeCompare(a.createdAt);

                        return b.year - a.year;
                      })
                      .slice(0, maxExperiencesDisplay)
                      .map((experience: any, index: number) => (
                        <Timeline.Item key={index} label={experience.year}>
                          {experience.role}: {experience.name},{' '}
                          {experience.type}
                          {experience.link && (
                            <>
                              <br />
                              <Link href={experience.link} target='_blank'>
                                <PlayCircleOutlined /> Video
                              </Link>
                            </>
                          )}
                        </Timeline.Item>
                      ))}
                  {shouldShowMoreExperience &&
                    [...talent.experiences]
                      .sort((a, b) => {
                        if (b.year === a.year && b.createdAt && a.createdAt)
                          return b.createdAt?.localeCompare(a.createdAt);

                        return b.year - a.year;
                      })
                      .map((experience: any, index: number) => (
                        <Timeline.Item key={index} label={experience.year}>
                          {experience.role}: {experience.name},{' '}
                          {experience.type}
                          {experience.link && (
                            <>
                              <br />
                              <Link href={experience.link} target='_blank'>
                                <PlayCircleOutlined /> Video
                              </Link>
                            </>
                          )}
                        </Timeline.Item>
                      ))}
                  {talent.experiences.length > maxExperiencesDisplay && (
                    <Timeline.Item
                      dot={
                        shouldShowMoreExperience ? (
                          <UpOutlined />
                        ) : (
                          <DownOutlined />
                        )
                      }
                    >
                      <Link
                        onClick={() =>
                          setShouldShowMoreExperience(!shouldShowMoreExperience)
                        }
                      >
                        Show{' '}
                        {shouldShowMoreExperience
                          ? 'Less'
                          : `${
                              talent.experiences.length - maxExperiencesDisplay
                            } More`}{' '}
                        Experiences
                      </Link>
                    </Timeline.Item>
                  )}
                </Timeline>
              ) : null}
            </section>
          ) : null}
          <section>
            <Title level={4} style={{ marginBottom: '0.25rem' }}>
              Skills
            </Title>
            <Paragraph>Languages</Paragraph>
            <Text className='attribute-detail small'>
              {talent.languages?.map(
                (language: any, index: number) =>
                  `${index !== 0 ? ', ' : ''}${language}`
              )}
            </Text>
            <Paragraph>Talents</Paragraph>
            {talent.skills?.length ? (
              <Text className='attribute-detail small'>
                {talent.skills?.map(
                  (skill: any, index: number) =>
                    `${index !== 0 ? ', ' : ''}${skill}`
                )}
              </Text>
            ) : null}
          </section>
          {hasSocialLinks ? (
            <section>
              <Title level={4} style={{ marginBottom: '0.75rem' }}>
                Social Media
              </Title>
              <Div>
                {talent.instagramId && talent.instaFollowers && (
                  <Div flex>
                    <Div className='social-link-container' noGrow>
                      <InstagramOutlined
                        style={{ fontSize: '2.5rem', color: '#E1306C' }}
                      />
                    </Div>
                    {talent.instaFollowers && (
                      <>
                        <Div>
                          <Paragraph>Followers</Paragraph>
                          <Text className='attribute-detail'>
                            {talent.instaFollowers
                              ? formatNumber(Number(talent.instaFollowers))
                              : '-'}
                          </Text>
                        </Div>
                        <Div>
                          <Paragraph>Post</Paragraph>
                          <Text className='attribute-detail'>
                            {talent.instaPosts
                              ? formatNumber(Number(talent.instaPosts))
                              : '-'}
                          </Text>
                        </Div>
                      </>
                    )}
                  </Div>
                )}
                {talent.tiktokId && talent.tiktokFollowers && (
                  <Div flex>
                    <Div className='social-link-container' noGrow>
                      <Icon component={() => <TikTokSvg size={40} />} />
                    </Div>
                    <Div>
                      <Paragraph>Followers</Paragraph>
                      <Text className='attribute-detail'>
                        {talent.tiktokFollowers
                          ? formatText(talent.tiktokFollowers)
                          : '-'}
                      </Text>
                    </Div>
                    <Div>
                      <Paragraph>Likes</Paragraph>
                      <Text className='attribute-detail'>
                        {talent.tiktokLikes
                          ? formatText(talent.tiktokLikes)
                          : '-'}
                      </Text>
                    </Div>
                  </Div>
                )}
                {talent.youtubeId && talent.ytSubscribers && (
                  <Div flex>
                    <Div className='social-link-container' noGrow>
                      <YoutubeFilled
                        style={{ fontSize: '2.5rem', color: '#FF0000' }}
                      />
                    </Div>

                    <Div>
                      <Paragraph>Subscribers</Paragraph>
                      <Text className='attribute-detail'>
                        {talent.ytSubscribers
                          ? formatText(talent.ytSubscribers)
                          : '-'}
                      </Text>
                    </Div>
                    <Div>
                      <Paragraph>Videos</Paragraph>
                      <Text className='attribute-detail'>
                        {talent.ytVideos ? formatText(talent.ytVideos) : '-'}
                      </Text>
                    </Div>
                  </Div>
                )}
                {talent.xiaohongshuId && talent.xhsFollowers && (
                  <Div flex>
                    <Div className='social-link-container' noGrow>
                      <XiaoHongShuSvg />
                    </Div>
                    <Div>
                      <Paragraph>Fans</Paragraph>
                      <Text className='attribute-detail'>
                        {talent.xhsFollowers
                          ? formatText(talent.xhsFollowers)
                          : '-'}
                      </Text>
                    </Div>
                    <Div>
                      <Paragraph>Likes and Favs</Paragraph>
                      <Text className='attribute-detail'>
                        {talent.xhsLikesFavs
                          ? formatText(talent.xhsLikesFavs)
                          : '-'}
                      </Text>
                    </Div>
                  </Div>
                )}
                {talent.douyinId && talent.douyinFollowers && (
                  <Div flex>
                    <Div className='social-link-container' noGrow>
                      <DouyinSvg />
                    </Div>
                    <Div>
                      <Paragraph>Followers</Paragraph>
                      <Text className='attribute-detail'>
                        {talent.douyinFollowers
                          ? formatNumber(Number(talent.douyinFollowers))
                          : '-'}
                      </Text>
                    </Div>
                    <Div>
                      <Paragraph>Likes</Paragraph>
                      <Text className='attribute-detail'>-</Text>
                    </Div>
                  </Div>
                )}
                {talent.facebookId && talent.facebookFollowers && (
                  <Div flex>
                    <Div className='social-link-container' noGrow>
                      <FacebookFilled
                        style={{ fontSize: '2rem', color: '#3b5998' }}
                      />
                    </Div>
                    <Div>
                      <Paragraph>Followers</Paragraph>
                      <Text className='attribute-detail'>
                        {talent.facebookFollowers
                          ? formatNumber(Number(talent.facebookFollowers))
                          : '-'}
                      </Text>
                    </Div>
                  </Div>
                )}
                {talent.twitterId && talent.twitterFolowers && (
                  <Div flex>
                    <Div className='social-link-container' noGrow>
                      <TwitterSvg />
                    </Div>
                    <Div>
                      <Paragraph>Followers</Paragraph>
                      <Text className='attribute-detail'>
                        {talent.twitterFolowers
                          ? formatNumber(Number(talent.twitterFolowers))
                          : '-'}
                      </Text>
                    </Div>
                  </Div>
                )}
              </Div>
            </section>
          ) : null}
          <section id='section-rating' style={{ marginBottom: '56px' }}>
            {featureToggle.rating &&
              ratingStats &&
              (isImpersonateTalent ||
                id === authUser.username ||
                (authHirer.hirerId && ratingStats?.totalRatingCount > 0)) && (
                <Div className='talent-profile-rating-section'>
                  <Div flex className='section-title'>
                    <Div>
                      <Title level={4} style={{ marginBottom: 0 }}>
                        Ratings
                      </Title>
                      {ratingStats.totalRatingCount > 0 && (
                        <Div style={{ color: '#A4A4A4', fontSize: 'small' }}>
                          {ratingStats.totalRatingCount} ratings
                        </Div>
                      )}
                    </Div>
                    {(isImpersonateTalent || id === authUser.username) && (
                      <RouteLink to='/talent/ratings'>View all</RouteLink>
                    )}
                  </Div>

                  <Div className='rating-item-container'>
                    <Div flex className='rating-item'>
                      <Div flex className='items-center'>
                        <span className='bold'>Will Hire Again</span>
                        <Tooltip
                          title={
                            'Percentage of hirers who will hire this talent again'
                          }
                        >
                          <InfoCircleOutlined className='info-icon' />
                        </Tooltip>
                      </Div>
                      <Div className='bold text-right'>
                        {ratingStats.hireAgain * 100}%
                      </Div>
                    </Div>
                    {ratingStats.totalMetricsCount > 0 &&
                      [
                        {
                          key: 'overallPerformance',
                          label: 'Overall Performance',
                          tooltip:
                            "Evaluate the talent's overall performance in the project.",
                        },
                        {
                          key: 'punctuality',
                          label: 'Punctuality',
                          tooltip:
                            "Assess the talent's punctuality in terms of arriving on set or delivering work on time.",
                        },
                        {
                          key: 'professionalism',
                          label: 'Professionalism',
                          tooltip:
                            "Access the talent's behavior and attitude and preparation for the role.",
                        },
                        {
                          key: 'responsiveness',
                          label: 'Responsiveness',
                          tooltip:
                            'How promptly did the talent respond to messages or request? Within 12 hours consider responsive.',
                        },
                        {
                          key: 'dialogueMastery',
                          label: 'Dialogue Mastery',
                          tooltip: 'How well the talent delivers the dialogue.',
                        },
                        {
                          key: 'profileRepresentation',
                          label: 'Profile Representation',
                          tooltip:
                            'To evaluate whether a person in pictures and videos are the same in reality',
                        },
                      ].map(({ key, label, tooltip }) => (
                        <Div key={key} flex className='rating-item'>
                          <Div flex className='items-center'>
                            <span className='bold'>{label}</span>
                            <Tooltip title={tooltip}>
                              <InfoCircleOutlined className='info-icon' />
                            </Tooltip>
                          </Div>

                          <Rate
                            count={Math.round(
                              ratingStats[key as keyof RatingStats] as number
                            )}
                            defaultValue={Math.round(
                              ratingStats[key as keyof RatingStats] as number
                            )}
                            disabled
                            className='text-black'
                          />
                        </Div>
                      ))}
                  </Div>

                  <Div flex className='written-review-container'>
                    {sortedRatings.map(
                      ({
                        ratingId,
                        authorProfilePicThumb,
                        authorCompanyName,
                        createdAt,
                        writtenReview,
                        roleId,
                      }) => (
                        <Div
                          key={ratingId}
                          className='written-review-item'
                          onClick={() =>
                            dispatch(
                              openModal({
                                modal: 'talent-rating-comment',
                                config: {
                                  modalProps: {
                                    closable: true,
                                    centered: true,
                                    footer: null as any,
                                  },
                                  context: { talentId: id, roleId },
                                },
                              })
                            )
                          }
                        >
                          <Div flex className='author-container'>
                            <img
                              src={authorProfilePicThumb}
                              className='author-profile-pic'
                            />
                            <Div>
                              <Div className='author-name'>
                                {authorCompanyName}
                              </Div>
                              <Div className='comment-date'>
                                {format(new Date(createdAt), 'dd MMM yyyy')}
                              </Div>
                            </Div>
                          </Div>
                          <Div className='content'>
                            {writtenReview && writtenReview.length > 140 ? (
                              <>
                                {`${writtenReview.substring(0, 141)}...`}
                                <Button type='link' className='p-0'>
                                  more
                                </Button>
                              </>
                            ) : (
                              writtenReview
                            )}
                          </Div>
                        </Div>
                      )
                    )}

                    {listRatingKey && (
                      <Button
                        type='link'
                        className='btn-load-more'
                        onClick={handleLoadMoreRatings}
                      >
                        <RightCircleOutlined />
                        <Div>Load More</Div>
                      </Button>
                    )}
                  </Div>
                </Div>
              )}

            {featureToggle.rating &&
              impersonator.impersonateId !== id &&
              !authHirer.hirerId &&
              !authUser.username && (
                <Div className='talent-profile-rating-section'>
                  <Div flex className='section-title'>
                    <Div>
                      <Title level={4} style={{ marginBottom: 0 }}>
                        Ratings
                      </Title>
                    </Div>
                  </Div>

                  <Div>
                    <LockOutlined />{' '}
                    <RouteLink
                      to='/hirer/login'
                      style={{ textDecoration: 'underline' }}
                    >
                      Login to view this talent&apos;s ratings
                    </RouteLink>
                  </Div>
                </Div>
              )}
          </section>
          {isProfileOwner ? (
            <div style={{ marginTop: 'calc(86px + 24px + 3rem)' }} />
          ) : null}
        </div>
        {isProfileOwner ? <BottomNavigation type='talent' /> : <Footer />}
      </Div>
    </Page>
  );
};

TalentProfilePage.asyncData = ({ id }: { [key: string]: string }) => {
  const talentService = useTalentService();
  return talentService.getProfile(id);
};

export default TalentProfilePage;
